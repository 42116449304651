import { HttpClient, HttpEvent, HttpEventType, HttpParams, HttpRequest } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-billing-export',
  templateUrl: './billing-export.component.html',
  styleUrls: ['./billing-export.component.scss']
})
export class BillingExportComponent {

  public date = new FormControl(new Date());
  public gettingData: boolean = false;

  constructor(private httpClient: HttpClient) { }

  public executeCode(){
    var dateObject = this.date.value as Date;
    var month = dateObject.getMonth();
    month++;
    this.gettingData = true;
    this.getCsv(dateObject.getFullYear().toString(), month.toString(), dateObject.getDate().toString()).subscribe((data) => {
      if (data) {
        const padSize = 2;
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            console.log(Math.round((data.loaded / data.total) * 100) + "%");
            break;
          case HttpEventType.Response:
            console.log("complete");
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = dateObject.getFullYear().toString() + '-' + month.toString().padStart(padSize, '0') + '-' + dateObject.getDate().toString().padStart(padSize, '0') + '-extracts.zip';
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            this.gettingData = false;
            break;
        }
      }
    });
  }

  public getCsv(year: string, month: string, day: string): Observable<HttpEvent<Blob>> {
    const params = new HttpParams()
      .set('year', year)
      .set('month', month)
      .set('day', day);

    return this.httpClient.request(new HttpRequest('GET', '/api/billing', { params, responseType: 'blob', reportProgress: true } ));
  }

}
