import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

//Services
import { NotificationService } from '../services/notification.service';
import { UserProfileService } from '../services/user-profile.service';
import { CampaignMonitorService } from '../services/campaign-monitor.service';
import { LicensingService } from '../services/licensing.service';
import { SubscriptionService } from '../services/subscription.service';
import { LeadsService } from '../services/leads.service';

//Main Components
import { HomeComponent } from '../components/home/home.component';
import { ManualActivationComponent } from '../components/manual-activation/manual-activation.component';
import { NotificationListComponent } from '../components/notification-list/notification-list.component';
import { NotificationComponent } from '../components/notification/notification.component';
import { DeliverFileComponent } from '../components/deliver-file/deliver-file.component';
import { DeliverTrainingFilesComponent } from '../components/deliver-training-files/deliver-training-files.component';
import { ListManagerComponent } from '../components/list-manager/list-manager.component';
import { LeadsListComponent } from '../components/leads-list/leads-list.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from "@angular/material/tree";
import { MatTableModule } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatOptionModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MsalModule, MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { ActivationsComponent } from '../components/activations/activations.component';
import { DeliveryComponent } from '../components/delivery/delivery.component';
import { UsersComponent } from '../components/users/users.component';
import { SubscriptionUnassignmentComponent } from '../components/subscription-unassignment/subscription-unassignment.component';
import { SubscriptionsComponent } from '../components/subscriptions/subscriptions.component';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { LoginFailedComponent } from '../components/login-failed/login-failed.component';
import { LandingComponent } from '../components/landing/landing.component';
import { AuthComponent } from '../components/auth/auth.component';
import { LoginComponent } from '../components/login/login.component';

import { MarkdownModule } from 'ngx-markdown';
import { AngularMarkdownEditorModule } from 'angular-markdown-editor';

import { ReplacementFileListComponent } from '../components/replacement-file-list/replacement-file-list.component';
import { ReplacementFileComponent } from '../components/replacement-file/replacement-file.component';
import { ReplacementFileActivationComponent } from '../components/replacement-file-activation/replacement-file-activation.component';
import { ReplacementFileExtensionComponent } from '../components/replacement-file-extension/replacement-file-extension.component';
import { LeadsComponent } from '../components/leads/leads.component';
import { LeadsQuestionsComponent } from '../components/leads-questions/leads-questions.component';
import { LeadsAnswersComponent } from '../components/leads-answers/leads-answers.component';
import { BillingExportComponent } from '../components/billing-export/billing-export.component';


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'bad0183e-4f51-4f81-88eb-d5e9fdf0f592',
      authority: 'https://login.microsoftonline.com/6aff9d39-041f-4ec4-be3b-f01089d3ce29',
      redirectUri: '/home/landing',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      //storeAuthStateInCookie: isIE,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([ // MSAL Interceptor Configuration
      ['https://graph.microsoft.com/v1.0/me', ['user.read']],
      ['/api/**', ['api://12eabea0-9555-46b8-95d6-8cab9ac14d1c/api-access']]
    ])
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    loginFailedRoute: '/auth/login-failed'
  };
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ManualActivationComponent,
        NotificationListComponent,
        NotificationComponent,
        DeliverFileComponent,
        DeliverTrainingFilesComponent,
        ListManagerComponent,
        ActivationsComponent,
        DeliveryComponent,
        SubscriptionsComponent,
        UsersComponent,
        SubscriptionUnassignmentComponent,
        LoginFailedComponent,
        LandingComponent,
        AuthComponent,
        LoginComponent,
        LeadsListComponent,
        ReplacementFileListComponent,
        ReplacementFileComponent,
        ReplacementFileActivationComponent,
        ReplacementFileExtensionComponent,
        LeadsComponent,
        LeadsQuestionsComponent,
        LeadsAnswersComponent,
        BillingExportComponent
    ],
    imports: [
        MsalModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatTableModule,
        MatStepperModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatSortModule,
        MatIconModule,
        MatTabsModule,
        MatDatepickerModule,
        MatOptionModule,
        MatSelectModule,
        MatDialogModule,
        MatCardModule,
        MatSnackBarModule,
        MatMenuModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatInputModule,
        MatButtonToggleModule,
        MatExpansionModule,
        MatTableModule,
        MatCardModule,
        MatGridListModule,
        MatChipsModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatTabsModule,
        MatStepperModule,
        MatSelectModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatListModule,
        MatTreeModule,
        MarkdownModule.forRoot(),
        AngularMarkdownEditorModule.forRoot()
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: MAT_DATE_LOCALE, useValue: navigator.language || 'en-AU' },
        { provide: LOCALE_ID, useValue: navigator.language || 'en_AU' },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
        NotificationService,
        UserProfileService,
        CampaignMonitorService,
        LicensingService,
        SubscriptionService,
        LeadsService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
