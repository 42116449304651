import { Component, OnInit } from '@angular/core';
import { ZendeskService } from '../../services/zendesk.service';
import { UserProfile } from '../../shared/models/user-profile.model';
import { UserProfileService } from '../../services/user-profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionService } from '../../services/subscription.service';
import { BusinessSubscription } from '../../shared/models/business-subscription';
import { UnassignRequest } from '../../shared/models/unassign-request';

@Component({
  selector: 'subscription-unassignment',
  templateUrl: './subscription-unassignment.component.html',
  styleUrls: ['./subscription-unassignment.component.scss']
})
export class SubscriptionUnassignmentComponent implements OnInit {

  public ticketNumber: string = "";
  public resetCompleted: boolean = false;
  public isSearchComplete: boolean = false;
  public searching: boolean = false;
  public resetting: boolean = false
  public impactedProfile: UserProfile = new UserProfile;
  public businesses: BusinessSubscription[] = [];
  public selectedBusiness: BusinessSubscription = new BusinessSubscription;
  public reason: string = "";

  constructor(private zendeskService: ZendeskService,
    private userProfileService: UserProfileService,
    private subscriptionService: SubscriptionService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  lookupTicket() {
    this.isSearchComplete = false;
    this.searching = true;
    this.impactedProfile = new UserProfile;
    this.resetCompleted = false;
    if (this.ticketNumber.length > 2) {
      this.zendeskService.getTicketDetails(this.ticketNumber).subscribe(profile => {
        this.impactedProfile = profile;
        this.searchLinkedBusinesses(profile);
        //this.searching = false;
        //this.isSearchComplete = true;
      },
        err => {
          this.snackBar.open(err);
          this.searching = false;
          this.isSearchComplete = false;
        });
    }
  }

  searchLinkedBusinesses(profile: UserProfile) {
    this.subscriptionService.businessSubscriptionList(profile.userName).subscribe(response => {
      this.businesses = response;
      this.searching = false;
      this.isSearchComplete = true;
    },
      err => {
        this.snackBar.open(err);
        this.searching = false;
        this.isSearchComplete = false;
      });
  }

  unlinkSubscription() {
    this.resetting = true;
    this.resetCompleted = false;

    var request = new UnassignRequest;
    request.userName = this.impactedProfile.userName;
    request.businessId = this.selectedBusiness.businessId;
    request.subscriptionId = this.selectedBusiness.subscriptionId;
    request.ticketNumber = this.ticketNumber;
    request.businessName = this.selectedBusiness.businessName;
    request.reason = this.reason;

    this.subscriptionService.unassignSubscription(request).subscribe(response => {
      this.resetCompleted = true;
      this.snackBar.open("Subscription and Business were Unlinked!")
      this.resetting = false;

      this.ticketNumber = "";
      this.resetCompleted = false;
      this.isSearchComplete = false;
      this.impactedProfile = new UserProfile;
      this.businesses = [];
      this.reason = "";
      this.selectedBusiness = new BusinessSubscription;

    },
      err => {
        this.snackBar.open(err);
        this.searching = false;
        this.isSearchComplete = false;
      });
  }
}
