import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BusinessInfo } from '../shared/models/business-info';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      `${error.error}`);
  }

  public availableBusinessesForReplacement(userName: string): Observable<BusinessInfo[]> {
    const params = new HttpParams()
      .set('userName', userName);

    return this.httpClient.get<BusinessInfo[]>('/api/business/availableBusinessesForReplacement', { params }).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  public activateReplacementFile(businessId: number): Observable<Object> {
    const params = new HttpParams()
      .set('businessId', businessId.toString());

    return this.httpClient.get('/api/business/activateReplacementFile', { params }).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  public replacementFileList(): Observable<BusinessInfo[]> {
    return this.httpClient.get<BusinessInfo[]>('/api/business/replacementFileList').pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  public extendExpiry(subscriptionId: number, newExpiry: Date): Observable<Object> {
    const params = new HttpParams()
      .set('subscriptionId', subscriptionId.toString())
      .set('newExpiry', newExpiry.toISOString());

    return this.httpClient.get('/api/business/extendReplacementExpiry', { params }).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
}
