import { Component, OnInit } from '@angular/core';
import { faQuestionCircle, faBell, faUserCircle, faBullhorn, faLightbulb, faGift, faSignalSlash} from '@fortawesome/pro-regular-svg-icons';
import {
  faAward,
  faEllipsisV,
  faHome,
  faShareAlt,
  faHistory,
  faInfoCircle,
  faCircleNotch,
  faBars
} from "@fortawesome/pro-regular-svg-icons";
import {
  faTimes,
  faDownload,
  faExclamationTriangle,
  faCloud,
  faUpload,
  faCog,
  faBinoculars,
  faUserLock,
} from "@fortawesome/pro-solid-svg-icons";
import { Router } from '@angular/router';
import { from } from 'rxjs';
import * as configcat from "configcat-js";
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from 'src/services/configuration.service';
import { ClientConfiguration } from 'src/shared/models/client-configuration';
import { ToggleService } from 'src/services/toggle.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public faQuestionCircle = faQuestionCircle;
  public faBell = faBell;
  public faUserCircle = faUserCircle;
  public faTimes = faTimes;
  public faBullhorn = faBullhorn;
  public faLightbulb = faLightbulb;
  public faGift = faGift;
  public faSignalSlash = faSignalSlash;
  public faAward = faAward;
  public faEllipsisV = faEllipsisV;
  public faHome = faHome;
  public faShareAlt = faShareAlt;
  public faHistory = faHistory;
  public faInfoCircle = faInfoCircle;
  public faCircleNotch = faCircleNotch;
  public faBars = faBars;
  public faCloud = faCloud;
  public faDownload = faDownload;
  public faExclamationTriangle = faExclamationTriangle;
  public faUpload = faUpload;
  public faCog = faCog;
  public faBinoculars = faBinoculars;
  public faUserLock = faUserLock;

  public activationsEnabled: boolean = false;
  public deliveryEnabled: boolean = false;
  public listManagerEnabled: boolean = false;
  public notificationsEnabled: boolean = false;
  public usersEnabled: boolean = false;
  public subscriptionsEnabled: boolean = false;
  public leadsEnabled: boolean = false;
  public replacementFileEnabled: boolean = false;

  public title: string;
  public userDisplayName: string;

  public isUpdateAvailable: boolean;
  public over: string = "over";

  constructor(private msal: MsalService,
    private _router: Router,
    private _http: HttpClient,
    private _configService: ConfigurationService,
    private _toggleService: ToggleService) { }

  ngOnInit() {
    this._http.get(GRAPH_ENDPOINT)
      .subscribe((profile: any) => {
        this.userDisplayName = profile.displayName;

        this._configService.configuration()
        .subscribe((config: ClientConfiguration) => {
          this.configureConfigCat(config, profile)
        });
      });
  }

  public returnToLogin()
  {
    this.msal.logout();
    this._router.navigateByUrl('/auth/login');
  }

  goHome() {
    this._router.navigateByUrl("/home/landing");
  }

  configureConfigCat(config: ClientConfiguration, profile: any) {
    if (this._toggleService.toggleClient) {
      this.getConfigValues(profile);
    } else {
      this._toggleService.toggleClient = configcat.createClientWithAutoPoll(
        config.configCatKey,
        {
          pollIntervalSeconds: config.configCatInterval,
          dataGovernance: configcat.DataGovernance.Global
        }
      );
      
      this.getConfigValues(profile);
    }
  }

  getConfigValues(profile: any) {
  from(
      this._toggleService.toggleClient.getValueAsync(
          "manageActivations",
          false,
          this._toggleService.getConfigToggleUser(profile.displayName, profile.email, profile.jobTitle)
      )
  ).subscribe((result) => {
      this.activationsEnabled = result;
  });

  from(
    this._toggleService.toggleClient.getValueAsync(
        "deliverClientFiles",
        false,
        this._toggleService.getConfigToggleUser(profile.displayName, profile.email, profile.jobTitle)
    )
).subscribe((result) => {
    this.deliveryEnabled = result;
});

from(
  this._toggleService.toggleClient.getValueAsync(
      "listManager",
      false,
      this._toggleService.getConfigToggleUser(profile.displayName, profile.email, profile.jobTitle)
  )
).subscribe((result) => {
  this.listManagerEnabled = result;
});

from(
  this._toggleService.toggleClient.getValueAsync(
      "manageNotifications",
      false,
      this._toggleService.getConfigToggleUser(profile.displayName, profile.email, profile.jobTitle)
  )
).subscribe((result) => {
  this.notificationsEnabled = result;
});

    from(
      this._toggleService.toggleClient.getValueAsync(
        "replacementFile",
        false,
        this._toggleService.getConfigToggleUser(profile.displayName, profile.email, profile.jobTitle)
      )
    ).subscribe((result) => {
      this.replacementFileEnabled = result;
    });

from(
  this._toggleService.toggleClient.getValueAsync(
      "manageUsers",
      false,
      this._toggleService.getConfigToggleUser(profile.displayName, profile.email, profile.jobTitle)
  )
).subscribe((result) => {
  this.usersEnabled = result;
});

from(
  this._toggleService.toggleClient.getValueAsync(
      "manageSubscriptions",
      false,
      this._toggleService.getConfigToggleUser(profile.displayName, profile.email, profile.jobTitle)
  )
).subscribe((result) => {
  this.subscriptionsEnabled = result;
});

from(
  this._toggleService.toggleClient.getValueAsync(
    "manageLeads",
    false,
    this._toggleService.getConfigToggleUser(profile.displayName, profile.email, profile.jobTitle)
  )
).subscribe((result) => {
    this.leadsEnabled = result;
});
  }
}
