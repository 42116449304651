import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { LeadsQuestion } from '../../shared/models/leads-question.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'leads-questions',
  templateUrl: './leads-questions.component.html',
  styleUrls: ['./leads-questions.component.scss']
})
export class LeadsQuestionsComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = ['resourceId', 'resourceName', 'questionId', 'questionText', 'questionWeight', 'modifiedDate'];

  public questionsLoaded: boolean = false;

  public maxWeight = 10;
  public minWeight = 1;

  public dataSource: MatTableDataSource<LeadsQuestion> = new MatTableDataSource<LeadsQuestion>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private leadsService: LeadsService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.loadQuestions();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  loadQuestions(): void {
    this.leadsService.listQuestions()
      .subscribe(questions => {
        this.dataSource.data = questions;
        this.questionsLoaded = true;
      },
        err => {
          this.snackBar.open(err);
        });
  }

  updateWeight(event, record) {
    if (record.questionWeight === null || record.questionWeight < this.minWeight) {
      record.questionWeight = this.minWeight;
    }
    else if (record.questionWeight > this.maxWeight) {
      record.questionWeight = this.maxWeight;
    }

    if (record.questionWeight !== record.questionWeightRef) {
      this.leadsService.updateWeights(record)
        .subscribe(result => {
          this.loadQuestions();
          this.snackBar.open(("Question ID: ").concat(record.questionId).concat(" has been Updated"));
        },
          err => {
            this.snackBar.open(err)
          });
    }
  }


    

}
