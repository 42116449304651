import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeadsAnswer } from '../../shared/models/leads-answer.model';
import { LeadsService } from '../../services/leads.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'leads-answers',
  templateUrl: './leads-answers.component.html',
  styleUrls: ['./leads-answers.component.scss']
})
export class LeadsAnswersComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = ['resourceName', 'questionId', 'questionWeight', 'questionText', 'answerText', 'answerDate'];

  public answersLoaded: boolean = false;

  public dataSource: MatTableDataSource<LeadsAnswer> = new MatTableDataSource<LeadsAnswer>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<LeadsAnswersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leadsService: LeadsService,
    private snackBar: MatSnackBar
  ) { }

  preRender(markDown: any) {
    // Here you have access to the markdown binding
    // before it is rendered
    return markDown;
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.loadAnswers();

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  loadAnswers(): void {
    this.leadsService.listAnswers(this.data.contactId)
      .subscribe(answers => {
      
        this.dataSource.data = answers;
        this.answersLoaded = true;
      },
      err => {
        this.snackBar.open(err);
      });
  }

}


