import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from '../components/home/home.component';
import { ListManagerComponent } from '../components/list-manager/list-manager.component';
import { NotificationListComponent } from '../components/notification-list/notification-list.component';
import { ActivationsComponent } from '../components/activations/activations.component';
import { DeliveryComponent } from '../components/delivery/delivery.component';
import { UsersComponent } from '../components/users/users.component';
import { SubscriptionsComponent } from '../components/subscriptions/subscriptions.component';
import { LoginFailedComponent } from '../components/login-failed/login-failed.component';
import { LandingComponent } from 'src/components/landing/landing.component';
import { AuthComponent } from 'src/components/auth/auth.component';
import { LoginComponent } from 'src/components/login/login.component';
import { LeadsComponent } from 'src/components/leads/leads.component';
import { ReplacementFileComponent } from '../components/replacement-file/replacement-file.component';
import { BillingExportComponent } from '../components/billing-export/billing-export.component';

const routes: Routes = [
  { path: '', redirectTo: '/home/landing', pathMatch: 'full' },
  //{ path: '', component: HomeComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'auth', component: AuthComponent,
     children: [
      { path: 'login', component: LoginComponent },
      { path: 'login-failed', component: LoginFailedComponent },
     ]
  },
  { path: 'home', component: HomeComponent, canActivate: [MsalGuard], children: [
    { path: 'landing', component: LandingComponent, canActivate: [MsalGuard] },
    { path: 'activations', component: ActivationsComponent, canActivate: [MsalGuard] },
    { path: 'delivery', component: DeliveryComponent, canActivate: [MsalGuard] },
    { path: 'list-manager', component: ListManagerComponent, canActivate: [MsalGuard] },
    { path: 'notifications', component: NotificationListComponent, canActivate: [MsalGuard] },
    { path: 'replacementfiles', component: ReplacementFileComponent, canActivate: [MsalGuard] },
    { path: 'users', component: UsersComponent, canActivate: [MsalGuard] },
    { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [MsalGuard] },
    { path: 'leads', component: LeadsComponent, canActivate: [MsalGuard] },
    { path: 'billing', component: BillingExportComponent, canActivate: [MsalGuard] },
  ] },
  
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
