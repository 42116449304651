import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UserProfile } from '../shared/models/user-profile.model';
import { PasswordReset } from '../shared/models/password-reset';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      `${error.error}`);
  }

  public list(): Observable<UserProfile[]> {
    return this.httpClient.get<UserProfile[]>('/api/userProfile/list').pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  public getProfile(userName: string): Observable<UserProfile> {
    const params = new HttpParams()
      .set('userName', userName);

    return this.httpClient.get<UserProfile>('/api/userProfile', { params }).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  public resetPassword(userName: string, ticketNumber: string): Observable<PasswordReset> {
    const params = new HttpParams()
      .set('userName', userName)
      .set('ticketNumber', ticketNumber);

    return this.httpClient.get<PasswordReset>('/api/user/resetPassword', { params }).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
}
