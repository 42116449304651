import { Component, OnInit } from '@angular/core';
import { ActivationRequest } from "../../shared/models/activation-request";
import { LicensingService } from '../../services/licensing.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
@Component({
  selector: 'manual-activation',
  templateUrl: './manual-activation.component.html',
  styleUrls: ['./manual-activation.component.scss']
})
export class ManualActivationComponent implements OnInit {

  private hasResult: boolean = false;
  public activationCode: string;
  public verificationCode: string;
  public faCopy = faCopy;
  public activation: ActivationRequest;

  constructor(private _licensingService: LicensingService,
    private _snackBar: MatSnackBar) {
    this.activation = new ActivationRequest;
  }

  ngOnInit() {
  }

  public get hasActivationResult() {
    return this.hasResult;
  }

  public get isValidActivation() {
    return this.activation
      && this.activation.userNumber
      && this.activation.hardwareId
  }

  public createCode(): void {
   
    if (this.isValidActivation) {
      this._licensingService.manualActivate(this.activation).subscribe(response => {
        if (response != undefined && response.amnestyWarningText) {
          this._snackBar.open(response.amnestyWarningText);
          this.hasResult = false;
        } else {
          this.activationCode = response.activationKey;
          this.verificationCode = response.checksum;
          this.hasResult = true;
        }
      },
        err => {
          this._snackBar.open(err);
        });
    }
    else {
      this.hasResult = false;
    }
  }
  
  /* To copy Text from Textbox */
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }


}
