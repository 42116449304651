import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../shared/models/notification.model';
import { NotificationComponent } from '../notification/notification.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  public displayedColumns: string[] = ['id', 'type', 'title', 'validFrom', 'expiry', 'readCount', 'actionEdit', 'actionDelete'];
  public changingNotification: boolean = false;

  public notificationList: Notification[] = [];
  public notificationsLoaded: boolean = false;
  
  public dataSource: MatTableDataSource<Notification> = new MatTableDataSource<Notification>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.loadNotifications();
  }

  loadNotifications(): void {
    this.notificationsLoaded = false;
    this.notificationService.list()
      .subscribe(notifications => {
        this.notificationList = notifications;
        this.dataSource.data = this.notificationList;
        this.notificationsLoaded = true;
      },
        err => {
          this.snackBar.open(err);
        });
  }

  openDialog(model: Notification): void {

    const dialogRef = this.dialog.open(NotificationComponent, {
      width: '80%',
      data: model
    });

    dialogRef.afterClosed().subscribe(notification => {
      this.changingNotification = true;
      if (notification) {
        if (notification.id > 0) {
          this.notificationService.update(notification).subscribe(result => {
            this.loadNotifications();
            this.snackBar.open("Notification Updated.");
            this.changingNotification = false;
          });
        } else {
          this.notificationService.create(notification).subscribe(result => {
            this.loadNotifications();
            this.snackBar.open("Notification Created.");
            this.changingNotification = false;
          });
        }
      }
    },
      err => {
        this.snackBar.open(err);
      });
  }

  deleteNotification(notification: Notification) {
    this.changingNotification = true;
    this.notificationService.delete(notification).subscribe(result => {
      this.loadNotifications();
      this.snackBar.open("Notification Deleted.");
      this.changingNotification = false;
    });
  }

  openNewDialog(): void {
    var model: Notification = new Notification;

    this.openDialog(model);
   
  }

}
