import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UnassignedSubscription } from '../shared/models/unassigned-subscription';
import { BusinessSubscription } from '../shared/models/business-subscription';
import { UnassignRequest } from '../shared/models/unassign-request';
import { SubscriptionInfo } from '../shared/models/subscription-info';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      `${error.error}`);
  }

  //string userName, int businessId, long subscriptionId, string ticketNumber, string businessName, string reason
  public unassignSubscription(request: UnassignRequest): Observable<UnassignedSubscription> {
   
    return this.httpClient.post<UnassignedSubscription>('/api/subscription/unassign', request).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  public businessSubscriptionList(userName: string): Observable<BusinessSubscription[]> {
    const params = new HttpParams()
      .set('userName', userName);

    return this.httpClient.get<BusinessSubscription[]>('/api/subscription/businessSubscriptions', { params }).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  public availableSubscriptionList(userName: string, includeWagemaster: boolean): Observable<SubscriptionInfo[]> {
    const params = new HttpParams()
      .set('userName', userName)
      .set('includeWagemaster', includeWagemaster.toString());

    return this.httpClient.get<SubscriptionInfo[]>('/api/subscription/availableSubscriptions', { params }).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

}
