import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { BusinessService } from '../../services/business.service';
import { BusinessInfo } from '../../shared/models/business-info';
import { ReplacementFileExtensionComponent } from '../replacement-file-extension/replacement-file-extension.component';

@Component({
  selector: 'replacement-file-list',
  templateUrl: './replacement-file-list.component.html',
  styleUrls: ['./replacement-file-list.component.scss']
})
export class ReplacementFileListComponent implements OnInit {
  public displayedColumns: string[] = ['id', 'name', 'ownerUserName', 'replacementStage', 'replacementName', 'timeRemaining', 'actionEdit']; 
  public changingBusiness: boolean = false;

  public businessList: BusinessInfo[] = [];
  public businessesLoaded: boolean = false;

  public dataSource: MatTableDataSource<BusinessInfo> = new MatTableDataSource<BusinessInfo>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private businessService: BusinessService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.loadBusinesses();
  }

  loadBusinesses(): void {
    this.businessesLoaded = false;
    this.businessService.replacementFileList()
      .subscribe({
        next: (notifications) => {
          this.businessList = notifications;
          this.dataSource.data = this.businessList;
          this.businessesLoaded = true;
        },
        error: (err: any) => {
          this.snackBar.open(err);
        }
      });
  }

  openDialog(element: BusinessInfo): void {

      const dialogRef = this.dialog.open(ReplacementFileExtensionComponent, {
        width: '40%',
        data: element
      });

      dialogRef.afterClosed().subscribe(() => {
        this.loadBusinesses();
      },
        err => {
          this.snackBar.open(err);
        });
    }

}
