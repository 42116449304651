import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BusinessService } from '../../services/business.service';
import { BusinessInfo } from '../../shared/models/business-info';

@Component({
  selector: 'app-replacement-file-extension',
  templateUrl: './replacement-file-extension.component.html',
  styleUrls: ['./replacement-file-extension.component.scss']
})
export class ReplacementFileExtensionComponent {
  public newExpiry: Date;
  constructor(public dialogRef: MatDialogRef<ReplacementFileExtensionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BusinessInfo,
    private businessService: BusinessService) {
    this.newExpiry = this.data.expiry;
  }

  onSubmit() {
    this.businessService.extendExpiry(this.data.subscriptionId, this.newExpiry).pipe(
      catchError((err) => {
        this.dialogRef.close();
        return throwError(err);
      })
    )
      .subscribe((data) => {
        this.dialogRef.close();
      });
  }

}
