import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification } from '../../shared/models/notification.model';
import { NotificationService } from '../../services/notification.service';
import { MarkdownService } from 'ngx-markdown';
import { EditorOption } from 'angular-markdown-editor';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  public types: string[] = [];
  editorOptions: EditorOption = {};

  constructor(
    public dialogRef: MatDialogRef<NotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Notification,
    private readonly notificationService: NotificationService,
    private readonly markdownService: MarkdownService
  ) {
    this.editorOptions = {
      parser: (val) => this.markdownService.parse(val.trim())
    };
  }

  preRender(markDown: any) {
    // Here you have access to the markdown binding
    // before it is rendered
    return markDown;
  }

  ngOnInit() {
    this.notificationService.types().subscribe((types) => {
      this.types = types;
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
