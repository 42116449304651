import { Component, OnInit } from '@angular/core';
import { UserProfileService } from '../../services/user-profile.service';
import { DeliveryService } from '../../services/delivery.service';
import { UserProfile } from '../../shared/models/user-profile.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionInfo } from '../../shared/models/subscription-info';

@Component({
  selector: 'deliver-file',
  templateUrl: './deliver-file.component.html',
  styleUrls: ['./deliver-file.component.scss']
})
export class DeliverFileComponent implements OnInit {

  public userNumber: string = "";
  public fileName: string = "";
  public selectedSubscriptionId: number;
  public ownerProfile: UserProfile = new UserProfile;
  public fileSelected: boolean = false;
  private file: File;
  public subscriptions: SubscriptionInfo[] = [];
  public searching: boolean = false;
  public delivering: boolean = false;
  public delivered: boolean = false;

  constructor(
    private userService: UserProfileService,
    private deliveryService: DeliveryService,
    private subscriptionService: SubscriptionService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  FileChange(fileInputEvent: any) {
    this.fileSelected = true;
    this.searching = true;
    this.file = <File>fileInputEvent.target.files[0];
    this.fileName = this.file.name;

    this.searchSubscriptions();
    
  }

  lookupUser() {
    this.searching = true;
    this.ownerProfile = new UserProfile;

    if (this.userNumber.length > 2) {
      this.userService.getProfile(this.userNumber).subscribe(profile => {
        this.ownerProfile = profile;
        this.searching = false;
        this.delivered = false;
        this.subscriptions = [];

        if (this.fileSelected) {
          this.searchSubscriptions();
        }

      },
        err => {
          this.searching = false;
          this.snackBar.open(err);
        });
    }
    
  }

  deliveryReady(): boolean {
    return this.ownerProfile.firstName != undefined && this.ownerProfile.firstName != '' && this.fileSelected && this.selectedSubscriptionId > 0
  }

  uploadFile() {
    this.delivering = true;
    if (this.ownerProfile.firstName && this.ownerProfile.lastName && this.userNumber) {
      this.deliveryService.deliverOwned(this.userNumber, this.selectedSubscriptionId, this.file).subscribe(result => {
        this.delivering = false;
        this.snackBar.open("File Delivered to client");
        this.delivered = true;
      },
        err => {
          this.delivering = false;
          this.snackBar.open(err);
        });
    }
  }

  searchSubscriptions() {
    let includeWagemaster = this.fileName.toLowerCase().endsWith('.wed')

    this.subscriptionService.availableSubscriptionList(this.userNumber, includeWagemaster).subscribe(subscriptions => {
      this.subscriptions = subscriptions;
      this.searching = false;

      if (this.subscriptions.length == 0) {
        this.snackBar.open("Client has no available subscriptions for file delivery!");
      }

    },
      err => {
        this.searching = false;
        this.snackBar.open(err);
      });
  }
}
