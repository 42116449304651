export class Notification {
  public id: string;
  public notificationType: string;
  public title: string;
  public description: string;
  public creation: string;
  public validFrom: string;
  public expiry: string;
  public readCount: string;
}
