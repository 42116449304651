import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UserProfile } from '../../shared/models/user-profile.model';
import { UserProfileService } from '../../services/user-profile.service';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { CampaignList } from '../../shared/models/campaign-list.model';
import { CampaignMonitorService } from '../../services/campaign-monitor.service';

@Component({
  selector: 'app-list-manager',
  templateUrl: './list-manager.component.html',
  styleUrls: ['./list-manager.component.scss'],
  providers: [DatePipe]
})

export class ListManagerComponent implements OnInit, AfterViewInit {
  settings = {
    actions: false,
    columns: {
      userId: {
        title: 'User ID',
      },
      userName: {
        title: 'User Name',
      },
      firstName: {
        title: 'First Name',
      },
      lastName: {
        title: 'Last Name',
      },
      companyName: {
        title: 'Company Name',
      },
      abn: {
        title: 'ABN',
      },
      planName: {
        title: 'Plan',
        filter: {
          type: 'list',
          config: {
            selectText: 'Show All',
            list: [],
          },
        }
      },
      planExpiry: {
        title: 'Plan Expiry',
        valuePrepareFunction: (planExpiry) => {
          return this.datePipe.transform(new Date(planExpiry), 'yyyy-MM-dd');
        }
      },
      city: {
        title: 'City',
        valuePrepareFunction: (city) => {
          return city.toUpperCase();
        }
      },
      state: {
        title: 'State',
        filter: {
          type: 'list',
          config: {
            selectText: 'Show All',
            list: [],
          },
        }
      },
      postcode: {
        title: 'Postcode',
      },
      userType: {
        title: 'User Type',
        filter: {
          type: 'list',
          config: {
            selectText: 'Show All',
            list: [],
          },
        }
      }
    },
  };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static:true }) sort: MatSort;

  public userProfilesLoaded: boolean = false;
  public campaignListsLoaded: boolean = false;

  private userProfiles: UserProfile[] = [];
  private campaignLists: CampaignList[] = [];
  

  dataSource: any; 
  totalProfiles = 0;

  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;

  constructor(
    private userProfileService: UserProfileService,
    private campaignMonitorService: CampaignMonitorService,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar

  ) { }

  ngOnInit() {

    this.userProfileService.list()
      .subscribe(profiles => {
        this.userProfiles = profiles;

        var unique = {};
        var distinct = [];

        var uniquestate = {};
        var distinctstate = [];

        var uniqueplan = {};
        var distinctplan = [];
        profiles.forEach(function (x) {
          if (!unique[x.userType]) {
            distinct.push({ value: x.userType, title: x.userType });
            unique[x.userType] = true;
          }

          if (!uniquestate[x.state]) {
            distinctstate.push({ value: x.state, title: x.state });
            uniquestate[x.state] = true;
          }

          if (!uniqueplan[x.planName]) {
            distinctplan.push({ value: x.planName, title: x.planName });
            uniqueplan[x.planName] = true;
          }
        },
          err => {
            this._snackBar.open(err);
          });

        
        this.settings.columns.userType.filter.config.list = distinct;
        this.settings.columns.state.filter.config.list = distinctstate;
        this.settings.columns.planName.filter.config.list = distinctplan;

        //this.dataSource = new LocalDataSource(this.userProfiles);
        this.totalProfiles = profiles.length;
        this.userProfilesLoaded = true;
      });


      this.campaignMonitorService.list()
      .subscribe(lists => {
        this.campaignLists = lists;
             
        this.campaignListsLoaded = true;
      });



  }

  ngAfterViewInit(): void {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }


  

}
