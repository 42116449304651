import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { LeadsRecord } from '../../shared/models/leads-record.model';
import { LeadsAnswersComponent } from '../leads-answers/leads-answers.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  faSmile,
  faFrown
} from "@fortawesome/pro-regular-svg-icons";

@Component({
  selector: 'leads-list',
  templateUrl: './leads-list.component.html',
  styleUrls: ['./leads-list.component.scss']
})
export class LeadsListComponent implements OnInit, AfterViewInit{
  public displayedColumns: string[] = ['contactId', 'emailAddress', 'contactName', 'contactPhone', 'isAgrimasterUser', 'agrimasterUserName', 'agrimasterName', 'agrimasterPhone', 'leadScore', 'actionAnswers', 'lastAnswerDate', 'hasBeenContacted', 'contactedDate'];

  public leadsLoaded: boolean = false;
  public faSmile = faSmile;
  public faFrown = faFrown;

  public dataSource: MatTableDataSource<LeadsRecord> = new MatTableDataSource<LeadsRecord>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private leadsService: LeadsService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.loadLeads();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  loadLeads(): void {
    this.leadsService.list()
      .subscribe(leads => {
        this.dataSource.data = leads;
        this.leadsLoaded = true;
      },
        err => {
          this.snackBar.open(err);
      });
  }

  updateCheckedList(event, record) {
    this.leadsService.update(record)
      .subscribe(result => {
        this.loadLeads();
        this.snackBar.open(record.emailAddress.concat(" has been Updated"));
      },
        err => {
          this.snackBar.open(err)
    });
  }

  /*For Answer Window Popout */
  openDialog(model: LeadsRecord): void {
    this.dialog.open(LeadsAnswersComponent, {
      width: '80%',
      data: model
    });
  }
}
