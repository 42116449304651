export class UserProfile {
  public id: string;
  public userName: string;
  public abn: string;
  public planName: string;
  public userType: string;
  public role: string;
  public planExpiry: string;
  public companyName: string;
  public firstName: string;
  public lastName: string;
  public city: string;
  public state: string;
  public postcode: string;
  public softwareSerialNumber: string;
  public edition: string;
  public creation: Date;
  public modification: Date;
}
