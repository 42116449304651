import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(private httpClient: HttpClient) { }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      `${error.error}`);
  }

  public deliverOwned(userName: string, subscriptionId: number, file: File): Observable<Object> {
    const params = new HttpParams()
      .set('userName', userName)
      .set('subscriptionId', subscriptionId.toString());

    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post('/api/delivery/deliverOwned', formData, { params }).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
}
