import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { LeadsAnswer } from '../shared/models/leads-answer.model';
import { LeadsQuestion } from '../shared/models/leads-question.model';
import { LeadsRecord } from '../shared/models/leads-record.model';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  constructor(
    private httpClient: HttpClient
  ) { }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      `${error.error}`);
  }

  public list(): Observable<LeadsRecord[]> {
    return this.httpClient.get<LeadsRecord[]>('/api/marketinglead/list').pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  public update(record: LeadsRecord): Observable<Object> {
    return this.httpClient.put('/api/marketinglead/update', record).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  public listQuestions(): Observable<LeadsQuestion[]> {
    return this.httpClient.get<LeadsQuestion[]>('/api/marketinglead/listQuestions').pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  public updateWeights(record: LeadsQuestion): Observable<Object> {
    return this.httpClient.put('/api/marketinglead/updateWeightings', record).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
  
  public listAnswers(contactId: number): Observable<LeadsAnswer[]> {
    const params = new HttpParams()
      .set('contactId', contactId.toString());

    return this.httpClient.get<LeadsAnswer[]>('/api/marketinglead/listAnswers', { params }).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
}
