import { Component } from '@angular/core';

@Component({
  selector: 'replacement-file',
  templateUrl: './replacement-file.component.html',
  styleUrls: ['./replacement-file.component.scss']
})
export class ReplacementFileComponent {

}
