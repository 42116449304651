import { Injectable } from '@angular/core';
import { User as ConfigUser } from "configcat-common/lib/RolloutEvaluator";
import { IConfigCatClient } from "configcat-common/lib/ConfigCatClient";

@Injectable({
  providedIn: 'root'
})
export class ToggleService {

  public toggleClient: IConfigCatClient;

  constructor() { }

  public getConfigToggleUser(displayName: string, email: string, jobTitle: string): ConfigUser {
    var userObject = {
        identifier: email,
        custom: {
            jobTitle: jobTitle,
            displayName: displayName
        },
    };
  
    return userObject;
  }
  

}
