import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PartialObserver } from 'rxjs';
import { BusinessService } from '../../services/business.service';
import { ZendeskService } from '../../services/zendesk.service';
import { BusinessInfo } from '../../shared/models/business-info';
import { UserProfile } from '../../shared/models/user-profile.model';

@Component({
  selector: 'replacement-file-activation',
  templateUrl: './replacement-file-activation.component.html',
  styleUrls: ['./replacement-file-activation.component.scss']
})
export class ReplacementFileActivationComponent implements OnInit {

  public ticketNumber: string = "";
  public selectedBusinessId: number;
  public ownerProfile: UserProfile = new UserProfile;
  public businesses: BusinessInfo[] = [];
  public searching: boolean = false;
  public activating: boolean = false;
  public activated: boolean = false;

  constructor(
    private businessService: BusinessService,
    private zendeskService: ZendeskService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  lookupTicket() {
    this.searching = true;
    this.ownerProfile = new UserProfile;
    this.businesses = [];
    this.activated = false;
    if (this.ticketNumber.length > 2) {

      const searchObserver: PartialObserver<UserProfile> = {
        next: profile => {
          this.ownerProfile = profile;
          this.searching = false;
          this.searchBusinesses();
        },
        error: err => {
          this.snackBar.open(err);
        },
        complete: () => console.log('')
      };

      this.zendeskService.getTicketDetails(this.ticketNumber)
        .subscribe(searchObserver);
    }
  }

  activationReady(): boolean {
    return this.ownerProfile.firstName != undefined && this.ownerProfile.firstName != '' && this.selectedBusinessId > 0
  }


  activateReplacement() {
    const searchObserver: PartialObserver<Object> = {
      next: () => {
        this.searching = false;
        this.searchBusinesses();
        this.snackBar.open("Replacement File Activated for Business!");
      },
      error: err => {
        this.searching = false;
        this.snackBar.open(err);
      },
      complete: () => console.log('')
    };

    this.businessService.activateReplacementFile(this.selectedBusinessId)
      .subscribe(searchObserver);
  }

  searchBusinesses() {

    const searchObserver: PartialObserver<BusinessInfo[]> = {
      next: businesses => {
        this.businesses = businesses;
        this.searching = false;

        if (this.businesses.length == 0) {
          this.snackBar.open("Client has no available businesses for replacement!");
        }
      },
      error: err => {
        this.searching = false;
        this.snackBar.open(err);
      },
      complete: () => console.log('')
    };

    this.businessService.availableBusinessesForReplacement(this.ownerProfile.userName)
      .subscribe(searchObserver);
  }

}
